import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

//Components
import Main from '../components/Questions/questions-main'


const IndexPage = () => (
  <Layout>
    <Seo title="Preguntas Frecuentes" description="Sección de preguntas frecuentes" />
    <Main />
  </Layout>
)

export default IndexPage
