import React, { useEffect } from "react"
import Breadcrumbs from "../breadcrumbs.jsx"
import { graphql, useStaticQuery } from "gatsby"

const Main = () => {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        name
      }
    }
  `)

  return (
    <section class="questions-main">
      <div class="container">
        <div class="row">
          <div className="col-12 mt-lg-5 mt-2">
            <Breadcrumbs
              props={[
                { name: realEstate.name, route: "/", location: "" },
                { name: "Preguntas frecuentes", route: "", location: "" },
              ]}
            />
          </div>
          <div class="col-12">
            <h2>Preguntas frecuentes.</h2>
          </div>

          <div class="col-9 mt-4">
            <h4>
              Preguntas <span>01.</span>{" "}
            </h4>
          </div>
          <div class="col-12">
            <div id="accordion">
              <div class="card">
                {/* <div class="card-header justify-content-between" id="headingOne5" data-toggle="collapse" data-target="#collapseOne5" aria-expanded="true" aria-controls="collapseOne5"> */}
                <div
                  class="card-header justify-content-between"
                  id="headingOne5"
                  data-bs-toggle="collapse"
                  href="#collapseOne5"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseOne5"
                >
                  <h5 class="my-0 row no-gutters">
                    <button class="text-start col-10">
                      ¿Cuál es nuestro horario de atención?
                    </button>
                    <div class="col-2">
                      <a class="icon-circle-arrow  text-right"></a>
                    </div>
                  </h5>
                </div>

                <div
                  id="collapseOne5"
                  class="collapse"
                  aria-labelledby="headingOne5"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      <b> Sucursal Av. Fleming 2800 Martinez </b> de Lunes a
                      Viernes de 10:00 a 18:00 y Sábados de 9:00 a 13:00. <br />
                      Telefonos 4793-8505. WhatsApp:11 2784-5344 <br />
                      <b> Sucursal Av.Libertador 13208 Martinez </b> de Lunes a
                      Viernes de 10:00 a 19:00 y Sábados de 9:00 a 13:00. <br />
                      Telefonos: 4733-0176. WhatsApp:11 2510-3744
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  class="card-header justify-content-between"
                  id="headingTwo5"
                  data-bs-toggle="collapse"
                  href="#collapseTwo5"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseTwo5"
                >
                  <h5 class="my-0 row no-gutters">
                    <button class="text-start col-10">
                      ¿Cuál es nuestra zona de influencia?
                    </button>
                    <div class="col-2">
                      <a class="icon-circle-arrow  text-right"></a>
                    </div>
                  </h5>
                </div>

                <div
                  id="collapseTwo5"
                  class="collapse"
                  aria-labelledby="headingTwo5"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Estamos presentes en el Gran Buenos Aires, especialmente
                      en la Zona Norte y en la Ciudad de Buenos Aires.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  class="card-header justify-content-between"
                  id="headingThree5"
                  data-bs-toggle="collapse"
                  href="#collapseThree5"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseThree5"
                >
                  <h5 class="my-0 row no-gutters">
                    <button class="text-start col-10">
                      ¿Qué documentación necesito para Vender o Alquilar mi
                      propiedad?
                    </button>
                    <div class="col-2">
                      <a class="icon-circle-arrow  text-right"></a>
                    </div>
                  </h5>
                </div>

                <div
                  id="collapseThree5"
                  class="collapse"
                  aria-labelledby="headingThree5"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      En necesario presentar: <br />
                      <br />
                      Fotocopia de la escritura del inmueble <br />
                      Fotocopia de las boletas de ARBA y ABL. <br />
                      Fotocopia de los servicios del inmueble. <br />
                      Fotocopia DNI del propietario. <br />
                      Fotocopia del plano de la propiedad. <br />
                      Fotocopia del reglamento de Co-propiedad. <br />
                      Fotocopia de la última liquidación de expensas. <br />
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  class="card-header justify-content-between"
                  id="headingFour5"
                  data-bs-toggle="collapse"
                  href="#collapseFour5"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseFour5"
                >
                  <h5 class="my-0 row no-gutters">
                    <button class="text-start col-10">
                      ¿Cómo promocionamos tu vivienda?
                    </button>
                    <div class="col-2">
                      <a class="icon-circle-arrow  text-right"></a>
                    </div>
                  </h5>
                </div>

                <div
                  id="collapseFour5"
                  class="collapse"
                  aria-labelledby="headingFour5"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Por medio de nuestro sitio web y de los diversos portales
                      inmobiliarios y por la gran presencia en Redes sociales y
                      acción con email marketing.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  class="card-header justify-content-between"
                  id="headingFive5"
                  data-bs-toggle="collapse"
                  href="#collapseFive5"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseFive5"
                >
                  <h5 class="my-0 row no-gutters">
                    <button class="text-start col-10">
                      ¿Qué es la reserva de compra?
                    </button>
                    <div class="col-2">
                      <a class="icon-circle-arrow  text-right"></a>
                    </div>
                  </h5>
                </div>

                <div
                  id="collapseFive5"
                  class="collapse"
                  aria-labelledby="headingFive5"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Es un documento, donde la parte compradora deja
                      establecidas las condiciones de la oferta por un inmueble.
                      Para esto es necesario entregar una suma de dinero que
                      queda depositada en la inmobiliaria como garantía de la
                      oferta.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  class="card-header justify-content-between"
                  id="headingSix5"
                  data-bs-toggle="collapse"
                  href="#collapseSix5"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseSix5"
                >
                  <h5 class="my-0 row no-gutters">
                    <button class="text-start col-10">¿Qué es el COTI?</button>
                    <div class="col-2">
                      <a class="icon-circle-arrow  text-right"></a>
                    </div>
                  </h5>
                </div>

                <div
                  id="collapseSix5"
                  class="collapse"
                  aria-labelledby="headingSix5"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Es el “código de oferta de transferencia de inmuebles”.
                      Nosotros lo tramitamos por vos. Este se realiza en la
                      AFIP, y es necesario para poder ofrecer y/o publicar
                      cualquier inmueble a la venta. La única exigencia es que
                      sea un valor igual o superior a $1.500.000.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  class="card-header justify-content-between"
                  id="headingSeven5"
                  data-bs-toggle="collapse"
                  href="#collapseSeven5"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseSeven5"
                >
                  <h5 class="my-0 row no-gutters">
                    <button class="text-start col-10">
                      ¿Qué es el Impuesto de Sellos?
                    </button>
                    <div class="col-2">
                      <a class="icon-circle-arrow  text-right"></a>
                    </div>
                  </h5>
                </div>

                <div
                  id="collapseSeven5"
                  class="collapse"
                  aria-labelledby="headingSeven5"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      El Impuesto de Sellos es un tributo que grava actos
                      jurídicos onerosos instrumentados, dependiendo el tipo de
                      instrumento: Contrato de alquiler residencial (GBA): 0,5%
                      Contrato de alquiler comercial (GBA y CABA): 1,2%
                      Escritura de compra venta: 3,6% La alícuota mencionada
                      debe ser aplicada sobre el valor fiscal ó valor de
                      escrituración y/o sobre el valor inmobiliario de
                      referencia (V.I.R.), el que resulte mayor; en el caso de
                      los contratos de alquiler, la base imponible es la
                      totalidad del contrato IVA incluído. En el caso de las
                      escrituras, se encuentran exentos los inmuebles destinados
                      a vivienda permanente y que constituyen la única propiedad
                      en cabeza de cada uno de los adquirentes, y siempre que el
                      valor de escrituración no sea mayor a los $750.000.
                      Aquellas operaciones que estén excentas pero que superen
                      el monto recientemente señalado, tributarán solo por el
                      excedente. Generalmente el costo del impuesto es soportado
                      y abonado por partes iguales entre comprador y vendedor; o
                      locador y locatario.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  class="card-header justify-content-between"
                  id="headingEight5"
                  data-bs-toggle="collapse"
                  href="#collapseEight5"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseEight5"
                >
                  <h5 class="my-0 row no-gutters">
                    <button class="text-start col-10">¿Qué es el ITI?</button>
                    <div class="col-2">
                      <a class="icon-circle-arrow  text-right"></a>
                    </div>
                  </h5>
                </div>

                <div
                  id="collapseEight5"
                  class="collapse"
                  aria-labelledby="headingEight5"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Es el Impuesto a la Transferencia de Inmuebles, un
                      impuesto que grava las transferencias ubicadas en el país,
                      y recae sobre las personas físicas y sucesiones indivisas
                      que no realizan como actividad comercial habitual la
                      compra-venta de inmuebles. Se considera transferencia a la
                      venta, permuta, cambio, dación en pago, aporte a
                      sociedades y todo acto de disposición por el que se
                      transmita el dominio a título oneroso. Este impuesto surge
                      de aplicar el 1,5 % sobre el valor de transferencia de
                      cada operación y será retenido por el escribano
                      interviniente o por el comprador, cuando no exista el
                      anterior. El ITI se paga sobre todos los inmuebles que
                      fueron adquiridos previo al 1 de Enero de 2018. Para los
                      casos contrarios, se reemplaza el ITI por el Impuesto a
                      las Ganancias. Todos los inmuebles adquiridos posterior al
                      1 de Enero de 2018, al momento de la venta, pagan el 15%
                      sobre la diferencia entre el valor de adquisición y el
                      valor de venta.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  class="card-header justify-content-between"
                  id="headingNine5"
                  data-bs-toggle="collapse"
                  href="#collapseNine5"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseNine5"
                >
                  <h5 class="my-0 row no-gutters">
                    <button class="text-start col-10">
                      ¿Cuándo estoy exento de pagar el ITI?
                    </button>
                    <div class="col-2">
                      <a class="icon-circle-arrow  text-right"></a>
                    </div>
                  </h5>
                </div>

                <div
                  id="collapseNine5"
                  class="collapse"
                  aria-labelledby="headingNine5"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Cuando vendés tu única vivienda o terreno para adquirir o
                      construir (dentro del término de un año) una propiedad
                      destinada a casa o habitación propia, podes solicitar de
                      manera gratuita el certificado de no retención del
                      impuesto a la transferencia de inmuebles. Es importante
                      realizar este trámite a través de un contador público, con
                      tiempo suficiente y notificar al escribano al inicio del
                      tramite de transmisión.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <p class="important">
              <b>Importante</b>: Los datos expresados en esta sección surgen en
              su mayoría de información proporcionada <br /> por el Ministerio
              de Interior, Obras Públicas y Vivienda.{" "}
              <a href="https://wwww.argentina.gob.ar/interior">
                https://wwww.argentina.gob.ar/interior
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Main
